
import { parsePhoneNumber } from 'awesome-phonenumber'
import phoneNumber from '~/mixins/common/phone-number'
export default {
    mixins: [phoneNumber],
    methods: {
        onValidateChange(obj) {
            this.generalInquiryValid = obj.isValid
            this.$emit('input', obj.number.e164)
        },
        onCountryChange(evt) {
            if (!this.phone || evt.iso2) {
                return null
            }
            const pn = parsePhoneNumber(
                this.phone?.toString(),
                evt?.iso2?.toString()
            )
            this.generalInquiryValid = pn.g.valid
        },
    },
}
